.App {
  display: flex;
  flex-direction: column;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* end reset */

@import url('https://fonts.googleapis.com/css?family=Noto+Serif&display=swap');
@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.header {
  width: 100%;
  height: 12%;
  background-color: black;
  display: flex;
  position: fixed;
  z-index: 2;
}

@media only screen and (min-device-width: 1100px) {
  .header {
    justify-content: space-between;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
  }

  .header h1 {
    font-size: 350%;
  }

  iframe {
    margin-right: 1rem;
  }

  .about-title {
    color: white;
    margin-top: 12%;
    align-self: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 200%;
  }

  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1%;
    color: black;
    font-family: 'Open Sans', sans-serif;
    background-color: white;
    background: 0.5;
    width: 40%;
    align-self: center;
    padding: 1%;
  }

  .image {
    margin-top: 2%;
    /* height: fit-content; */
    height: auto;
    width: 50%;
    border: solid black 0.1rem;
    background-image: url('https://media-exp1.licdn.com/dms/image/C4E03AQGX-1dxPQzK-g/profile-displayphoto-shrink_200_200/0?e=1590624000&v=beta&t=TNjuipCeKT5oSfnIK574hY6KKWW1XDRhMJLXnaba2uU');
    background-size: 100%;
    background-position: center;
  }

  .about-blurb {
    margin-top: 1%;
    width: 100%;
    text-align: center;
  }

  .resume {
    margin-bottom: 2.5%;
  }

  .project {
    width: 30%;
  }

  .project h1 {
    font-size: 150%;
  }

  .built-with {
    margin-bottom: 3%;
  }

  .blog-description h1 {
    font-size: large;
    margin-top: 2%;
  }

  .blog-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .name-input {
    width: 30%;
    font-size: 1rem;
    height: 2rem;
    text-indent: 1%;
    margin-bottom: 1%;
  }
  
  .email-input {
    font-size: 1rem;
    height: 2rem;
    width: 30%;
    text-indent: 1%;
    margin-bottom: 1%;
  }
  
  .message-input {
    font-family: 'Open Sans', sans-serif;
    align-self: center;
    width: 30%;
    font-size: 1rem;
    font-weight: normal;
    height: 10rem;
    margin-bottom: 1%;
    text-indent: 1%;
  }
  
  .pageclip-form button {
    cursor: pointer;
    width: max-content;
    align-self: center;
    margin-bottom: 1%;
    font-size: 1rem;
    border: none;
    font-family: 'Open Sans', sans-serif;
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1050px) {
  .header {
    justify-content: center;
    padding-top: 4%;
  }

  .header h1 {
    font-size: 2.6rem;
  }

  iframe {
    margin-left: 2rem;
  }

  .about-title {
    color: white;
    margin-top: 25%;
    align-self: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 200%;
  }

  .image {
    height: 15rem;
    width: auto;
    border: solid black 0.1rem;
    background-image: url('https://media-exp1.licdn.com/dms/image/C4E03AQGX-1dxPQzK-g/profile-displayphoto-shrink_200_200/0?e=1590624000&v=beta&t=TNjuipCeKT5oSfnIK574hY6KKWW1XDRhMJLXnaba2uU');
    background-size: 100%;
    background-position: center;
    margin-top: 4%;
  }

  .about-blurb {
    margin-top: 1%;
    text-align: center;
    font-size: 1rem;
  }

  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1%;
    color: black;
    font-family: 'Open Sans', sans-serif;
    background-color: white;
    background: 0.5;
    width: 74%;
    align-self: center;
  }

  .resume {
    margin-bottom: 3.5%;
  }

  .project {
    width: 30%;
  }

  .project h1 {
    font-size: 120%;
  }

  .built-with {
    margin-bottom: 5%;
  }

  .blog-description h1 {
    font-size: 0.7rem;
    margin-top: 2%;
  }

  .blog-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .name-input {
    width: 50%;
    font-size: 1rem;
    height: 2rem;
    text-indent: 1%;
    margin-bottom: 1%;
  }
  
  .email-input {
    font-size: 1rem;
    height: 2rem;
    width: 50%;
    text-indent: 1%;
    margin-bottom: 1%;
  }
  
  .message-input {
    font-family: 'Open Sans', sans-serif;
    align-self: center;
    width: 50%;
    font-size: 1rem;
    font-weight: normal;
    height: 10rem;
    margin-bottom: 1%;
    text-indent: 1%;
  }
  
  .pageclip-form button {
    cursor: pointer;
    width: max-content;
    text-align: center;
    align-self: center;
    margin-bottom: 1%;
    font-size: 1rem;
    border: none;
    background-color: white;
    color: black;
    font-family: 'Open Sans', sans-serif;
  }
}

@media only screen and (max-device-width: 500px) {
  .header {
    justify-content: center;
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    height: max-content
  }

  .header h1 {
    text-align: center;
    font-size: 200%;
  }

  iframe {
    margin-top: 3%;
  }

  .header h3 {
    text-align: center;
  }

  .about-title {
    color: white;
    margin-top: 55%;
    align-self: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 200%;
    margin-bottom: 1%;
  }

  .section-title {
    margin-bottom: 2%;
  }

  .image {
    height: 8rem;
    width: auto;
    border: solid black 0.1rem;
    background-image: url('https://media-exp1.licdn.com/dms/image/C4E03AQGX-1dxPQzK-g/profile-displayphoto-shrink_200_200/0?e=1590624000&v=beta&t=TNjuipCeKT5oSfnIK574hY6KKWW1XDRhMJLXnaba2uU');
    background-size: 100%;
    background-position: center;
    margin-top: 5%;
  }

  .about-blurb {
    margin-top: 1%;
    text-align: center;
    font-size: 0.9rem;
  }

  .resume {
    margin-bottom: 4%;
  }

  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1%;
    color: black;
    font-family: 'Open Sans', sans-serif;
    background-color: white;
    background: 0.5;
    width: 80%;
    align-self: center;
    height: max-content;
  }

  .blog-section {
    display: none;
  }

  .blog-title {
    display: none;
  }

  .name-input {
    width: 70%;
    font-size: 1rem;
    height: 2rem;
    text-indent: 1%;
    margin-bottom: 1%;
  }
  
  .email-input {
    font-size: 1rem;
    height: 2rem;
    width: 70%;
    text-indent: 1%;
    margin-bottom: 1%;
  }
  
  .message-input {
    font-family: 'Open Sans', sans-serif;
    align-self: center;
    width: 70%;
    font-size: 1rem;
    font-weight: normal;
    height: 10rem;
    margin-bottom: 1%;
    text-indent: 1%;
  }

  .pageclip-form button {
    cursor: pointer;
    width: max-content;
    text-align: center;
    align-self: center;
    margin-top: 1%;
    margin-bottom: 1%;
    font-size: 1rem;
    border: none;
    background-color: white;
    color: black;
    font-family: 'Open Sans', sans-serif;
  }

  .projects {
    flex-direction: column;
    align-items: center;

  }

  .project {
    width: 50%;
  }

  .project h1{
    align-self: center;
  }

  .project-descrip, .built {
    display: none;
  }

  .built-with {
    color: gray;
    font-size: 5%;
    margin: 4%;
  }
}

.header h1 {
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  color: white;
}

.header h3 {
  font-family: 'Open Sans', sans-serif;
  color: lightgrey;
  font-size: 1.5rem;
}

.name {
  align-items: center;
  margin-left: 1%;
}

.navbar {
  width: 20%;
}

.page-links {
  display: flex;
  justify-content: space-evenly;
  color: white;
  font-family: 'Open Sans', sans-serif;
  width: 100%;
}

.links {
  display: flex;
  justify-content: center;
  margin: 10%;
}

.icon {
  margin: 3%;
  width: 2rem;
  height: 2rem;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.resume {
  text-decoration: none;
  text-align: center;
  color: black;
  font-size: 80%;
}

.github {
  background-image: url('https://image.flaticon.com/icons/svg/25/25231.svg');
}

.linkedin {
  background-image: url('https://image.flaticon.com/icons/png/512/61/61109.png');
}

.blog {
  background-image: url('https://cdn.iconscout.com/icon/free/png-256/medium-47-433328.png');
}

.github:hover {
  background-image: url('https://image.flaticon.com/icons/svg/25/25231.svg');
  opacity: 0.5;
}

.linkedin:hover {
  background-image: url('https://image.flaticon.com/icons/png/512/61/61109.png');
  opacity: 0.5;
}

.blog:hover {
  background-image: url('https://cdn.iconscout.com/icon/free/png-256/medium-47-433328.png');
  opacity: 0.5;
}

.youtube {
  background-image: url('https://clipartart.com/images/youtube-clipart-black-and-white-1.png');
}

.about-blurb p {
  margin: 1%;
  line-height: 120%;
}

.section-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 200%;
  font-weight: bold;
  align-self: center;
  color: white;
  margin-top: 5%;
}

.projects {
  width: 70%;
  align-self: center;
  margin-top: 1%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-family: 'Open Sans', sans-serif;
  background-color: white;
  padding: 2%;
}

.project {
  text-align: center;
  color: black;
  margin: 1%;
}

.project h1 {
  font-weight: bold;
  margin-bottom: 1%;
}

.built {
  margin-top: 5%;
}

.project-photo {
  width: 100%;
  height: auto;
  border: solid black 0.1rem;
}

.project-photo:hover {
  width: 100%;
  height: auto;
  border: solid gray 0.1rem;
}

.hovering:hover {
  color: gray;
}

.project-icon {
  cursor: pointer;
  margin: 0.7%;
  width: 1.3rem;
  height: 1.3rem;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
}

.blog-section {
  margin-top: 1%;
  background-color: white;
  width: 70%;
  flex-wrap: wrap;
  align-self: center;
  font-family: 'Open Sans', sans-serif;
  padding: 2%;
}

.blog-description {
  align-self: center;
  width: 30%;
  margin: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blog-description:hover {
  align-self: center;
  width: 30%;
  margin: 1%;
  display: flex;
  color: gray;
}

.blog-description h1 {
  font-weight: bold;
  width: 13%;
  position: absolute;
  text-align: center;
  align-self: center;
  margin-bottom: 2%;
}

.blog-description a {
  display: flex;
  justify-content: center;
}

.blog-photo {
  width: 70%;
  border: solid black 0.1rem;
  opacity: 0.4;
}

.blog-photo:hover {
  width: 70%;
  border: solid gray 0.1rem;
  opacity: 0.4;
}

.pageclip-form {
  display: flex;
  flex-direction: column;
  margin-top: 1%;
  margin-bottom: 5%;
}

.pageclip-form input {
  font-family: 'Open Sans', sans-serif;
  margin-left: auto;
  margin-right: auto;
}


